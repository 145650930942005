import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
// import { SpeedInsights } from "@vercel/speed-insights/react";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const App = () => {
  const [languages, setLanguages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({
    id: null,
    name: "",
    projects: 0,
    rank: 0,
  });

  // ANCHOR fetchLanguages function
  async function fetchLanguages() {
    let { data, error } = await supabase.from("Languages").select("*");

    if (error) console.log("Error fetching data", error);
    else return data;
  }

  // ANCHOR addLanguage function
  async function addLanguage(name, proficiency) {
    let { data, error } = await supabase
      .from("Languages")
      .insert([{ name: name, proficiency: proficiency }]);

    if (error) console.log("Error inserting data", error);
    else return data;
  }

  // ANCHOR deleteLanguage function
  async function deleteLanguage(id) {
    let { data, error } = await supabase
    .from("Languages").delete().match({ id });

    if (error) console.log("Error deleting data", error);
    else return data;
  }

  // ANCHOR updateProject function
  async function updateProject(id, updates) {
    const { data, error } = await supabase
      .from("Projects")
      .update(updates)
      .match({ id });
    if (error) console.log("Error updating project", error);
    else return data;
  }

  // ANCHOR updateLanguageProficiency function
  async function updateLanguageProficiency(id, proficiency) {
    const { data, error } = await supabase
      .from("Languages")
      .update({ proficiency })
      .match({ id });
    if (error) console.log("Error updating language", error);
    else return data;
  }

  // ANCHOR updateToDoStatus function
  async function updateToDoStatus(id, is_completed) {
    const { data, error } = await supabase
      .from("ToDos")
      .update({ is_completed })
      .match({ id });
    if (error) console.log("Error updating to-do item", error);
    else return data;
  }

  // ANCHOR deleteProject function
  async function deleteProject(id) {
    const { data, error } = await supabase
      .from("Projects")
      .delete()
      .match({ id });
    if (error) console.log("Error deleting project", error);
    else return data;
  }

  // ANCHOR removeLanguageFromProject function
  async function removeLanguageFromProject(project_id, language_id) {
    const { data, error } = await supabase
      .from("Project_Languages")
      .delete()
      .match({ project_id, language_id });
    if (error) console.log("Error removing language from project", error);
    else return data;
  }

  // ANCHOR deleteToDoItem function
  async function deleteToDoItem(id) {
    const { data, error } = await supabase.from("ToDos").delete().match({ id });
    if (error) console.log("Error deleting to-do item", error);
    else return data;
  }

  useEffect(() => {
    fetchLanguages().then((data) => {
      if (data) setLanguages(data);
    });
  }, []);

  // ANCHOR saveLanguages function
  const saveLanguages = (languages) => {
    fetch("http://localhost:5000/languages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: languages }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((err) => console.error("Failed to save languages", err));
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setCurrentLanguage((prev) => ({ ...prev, [name]: value }));
  };

  // ANCHOR handleSubmit function
const handleSubmit = async () => {
  if (isEditing) {
    // Call updateLanguageProficiency to update the language in Supabase
    const updatedLanguage = await updateLanguageProficiency(
      currentLanguage.id,
      currentLanguage.rank
    );
    if (updatedLanguage) {
      const updatedLanguages = languages.map((lang) =>
        lang.id === currentLanguage.id
          ? { ...lang, ...updatedLanguage[0] }
          : lang
      );
      setLanguages(updatedLanguages);
    }
  } else {
    // Call addLanguage to add the new language to Supabase
    const newLanguage = await addLanguage(
      currentLanguage.name,
      currentLanguage.rank
    );
    if (newLanguage) {
      setLanguages([...languages, ...newLanguage]);
    }
  }
  handleClose();
};

// SECTION separate submit functions
// ANCHOR handleAddNewSubmit function
// const handleAddNewSubmit = async () => {
//   if (!isEditing) {
//     const newLanguage = await addLanguage(
//       currentLanguage.name,
//       currentLanguage.rank
//     ); // Assuming 'rank' as proficiency for simplicity.
//     if (newLanguage) {
//       setLanguages([...languages, ...newLanguage]); // Spread newLanguage in case it's an array.
//       handleClose();
//     }
//   } else {
//     handleUpdateSubmit();
//   }
// };

// ANCHOR handleUpdateSubmit function
// const handleUpdateSubmit = async () => {
//   if (isEditing) {
//     const updatedLanguage = await updateLanguageProficiency(
//       currentLanguage.id,
//       currentLanguage.rank
//     );
//     if (updatedLanguage) {
//       const updatedLanguages = languages.map((lang) =>
//         lang.id === currentLanguage.id
//           ? { ...lang, ...updatedLanguage[0] }
//           : lang
//       );
//       setLanguages(updatedLanguages);
//       handleClose();
//     }
//   }
// };
// !SECTION

  // ANCHOR handleEdit function
  const handleEdit = (language) => {
    setIsEditing(true);
    setCurrentLanguage(language);
    handleShow();
  };

  // ANCHOR handleAddNew function
  const handleAddNew = () => {
    setIsEditing(false);
    setCurrentLanguage({ id: null, name: "", projects: 0, rank: 0 });
    handleShow();
  };

  return (
    <div className="App container mt-5">
      <Button variant="primary" onClick={handleAddNew}>
        Add New Language
      </Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Language</th>
            <th>Projects</th>
            <th>Rank</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {languages.map((lang) => (
            <tr key={lang.id}>
              <td>{lang.id}</td>
              <td>{lang.name}</td>
              <td>{lang.projects}</td>
              <td>{lang.rank}</td>
              <td>
                <Button variant="secondary" onClick={() => handleEdit(lang)}>
                  Edit
                </Button>
                <Button variant="danger" onClick={() => deleteLanguage(lang.id)}>
                  Delete
                  </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Language" : "Add New Language"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formLanguageName">
              <Form.Label>Language Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentLanguage.name}
                onChange={handleFormChange}
                placeholder="Enter programming language"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formProjects">
              <Form.Label>Projects</Form.Label>
              <Form.Control
                type="number"
                name="projects"
                value={currentLanguage.projects}
                onChange={handleFormChange}
                placeholder="Number of projects"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRank">
              <Form.Label>Rank</Form.Label>
              <Form.Control
                type="number"
                name="rank"
                value={currentLanguage.rank}
                onChange={handleFormChange}
                placeholder="Rank"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default App;
